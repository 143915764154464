$danger: #c31a23;
$link: $danger;

@import '../node_modules/bulma/bulma';

.navbar-brand .navbar-item {
  &:focus, &:hover {
    background-color: white !important;
  }

  img {
    max-height: none;
  }
}

.navbar-start {
  .navbar-item, .navbar-item .button {
    padding: 0.5rem 2.5rem;
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.homepage-hero {
  .hero-body {
    background-position: center center;
    background-size: cover;
    text-align: center;

    img {
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 1024px) {
    &.is-halfheight {
      min-height: 30vh;
    }
  }
}

.hero.is-quarterheight {
  min-height: 25vh;
}

a.navbar-item.is-active, a.navbar-item:hover {
  color: #0a0a0a;
}

.navbar-brand {
  align-items: center;

  .navbar-item {
    padding: 1rem 0;
  }
}

.navbar-burger {
  height: 7.5rem;
  width: 6.5rem;
}

.navbar-brand .navbar-item img {
  max-height: 80px;
}

@media screen and (max-width: 1024px) {
  .navbar-brand .navbar-item img {
    max-height: 7vh;
  }
}

.service-columns {
  svg {
    color: #c31b22;
  }
}

.text-primary {
  color: #c31b22;
}

@media screen and (max-width: 720px) {
  body {
    overflow-x: hidden;
  }
}

.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  height: 100%;
  position: relative;
  min-height: 200px;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}